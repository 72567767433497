import { QpNamedMiniRange } from '@library/classes/qp-named-mini-range/qp-named-mini-range';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { qpAssert } from '@library/functions/checks/qp-assert';
import {
  EQpAQLCodeLetter,
  EQpAQLInspectionLevel,
  EQpAQLLotSize,
  EQpAQLPoint,
  EQpAQLSampleSize,
  IQpAQL,
  IQpAQLSamplingSize,
  IQpAQLSamplingSizeResult,
  IQpAQLSamplingSizes,
  IQpAQLSamplingSizeValue,
} from '@library/models/qp-aql.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil, last, sortBy } from 'lodash/index';

const AQL_CODE_LETTER_MAPPER: Record<EQpAQLLotSize, Record<EQpAQLInspectionLevel, EQpAQLCodeLetter>> = {
  // Non standard
  [EQpAQLLotSize.LOT_SIZE_0_TO_0]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_X,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_X,
  },
  // Non standard
  [EQpAQLLotSize.LOT_SIZE_1_TO_1]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_Z,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_Z,
  },
  [EQpAQLLotSize.LOT_SIZE_2_TO_8]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_A,
  },
  [EQpAQLLotSize.LOT_SIZE_9_TO_15]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_A,
  },
  [EQpAQLLotSize.LOT_SIZE_16_TO_25]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_B,
  },
  [EQpAQLLotSize.LOT_SIZE_26_TO_50]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_A,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_C,
  },
  [EQpAQLLotSize.LOT_SIZE_51_TO_90]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_F,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_C,
  },
  [EQpAQLLotSize.LOT_SIZE_91_TO_150]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_F,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_G,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_D,
  },
  [EQpAQLLotSize.LOT_SIZE_151_TO_280]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_G,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_H,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_E,
  },
  [EQpAQLLotSize.LOT_SIZE_281_TO_500]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_F,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_H,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_J,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_B,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_E,
  },
  [EQpAQLLotSize.LOT_SIZE_501_TO_1200]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_G,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_J,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_K,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_F,
  },
  [EQpAQLLotSize.LOT_SIZE_1201_TO_3200]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_H,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_K,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_L,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_G,
  },
  [EQpAQLLotSize.LOT_SIZE_3201_TO_10000]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_J,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_L,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_M,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_F,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_G,
  },
  [EQpAQLLotSize.LOT_SIZE_10001_TO_35000]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_K,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_M,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_N,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_C,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_F,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_H,
  },
  [EQpAQLLotSize.LOT_SIZE_35001_TO_150000]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_L,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_N,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_P,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_G,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_J,
  },
  [EQpAQLLotSize.LOT_SIZE_150001_TO_500000]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_M,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_P,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_Q,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_G,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_J,
  },
  [EQpAQLLotSize.LOT_SIZE_500001_TO_INFINITY]: {
    [EQpAQLInspectionLevel.I]: EQpAQLCodeLetter.LETTER_N,
    [EQpAQLInspectionLevel.II]: EQpAQLCodeLetter.LETTER_Q,
    [EQpAQLInspectionLevel.III]: EQpAQLCodeLetter.LETTER_R,
    [EQpAQLInspectionLevel.S1]: EQpAQLCodeLetter.LETTER_D,
    [EQpAQLInspectionLevel.S2]: EQpAQLCodeLetter.LETTER_E,
    [EQpAQLInspectionLevel.S3]: EQpAQLCodeLetter.LETTER_H,
    [EQpAQLInspectionLevel.S4]: EQpAQLCodeLetter.LETTER_K,
  },
};
const AQL_SAMPLING_SIZE_MAPPER: Record<EQpAQLCodeLetter, Record<AqlValue, IQpAQLSamplingSizeValue>> = {
  [EQpAQLCodeLetter.LETTER_A]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_2,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_2,
    },
  },
  [EQpAQLCodeLetter.LETTER_B]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_3,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_2,
    },
  },
  [EQpAQLCodeLetter.LETTER_C]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
  },
  [EQpAQLCodeLetter.LETTER_D]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
  },
  [EQpAQLCodeLetter.LETTER_E]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
  },
  [EQpAQLCodeLetter.LETTER_F]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
  },
  [EQpAQLCodeLetter.LETTER_G]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
  },
  [EQpAQLCodeLetter.LETTER_H]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
  },
  [EQpAQLCodeLetter.LETTER_J]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
  },
  [EQpAQLCodeLetter.LETTER_K]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
  },
  [EQpAQLCodeLetter.LETTER_L]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  [EQpAQLCodeLetter.LETTER_M]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  [EQpAQLCodeLetter.LETTER_N]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  [EQpAQLCodeLetter.LETTER_P]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_1,
      rejectPoint: EQpAQLPoint.POINT_2,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  [EQpAQLCodeLetter.LETTER_Q]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_2,
      rejectPoint: EQpAQLPoint.POINT_3,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  [EQpAQLCodeLetter.LETTER_R]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_3,
      rejectPoint: EQpAQLPoint.POINT_4,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_5,
      rejectPoint: EQpAQLPoint.POINT_6,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_7,
      rejectPoint: EQpAQLPoint.POINT_8,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_10,
      rejectPoint: EQpAQLPoint.POINT_11,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_14,
      rejectPoint: EQpAQLPoint.POINT_15,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_21,
      rejectPoint: EQpAQLPoint.POINT_22,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
  },
  // Non standard
  [EQpAQLCodeLetter.LETTER_Z]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_1,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_1,
      sampleSize: EQpAQLSampleSize.SIZE_2,
    },
  },
  // Non standard
  [EQpAQLCodeLetter.LETTER_X]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EQpAQLPoint.POINT_0,
      rejectPoint: EQpAQLPoint.POINT_0,
      sampleSize: EQpAQLSampleSize.SIZE_0,
    },
  },
};
const CODE_LETTER_TO_SAMPLE_SIZE = {
  [EQpAQLCodeLetter.LETTER_A]: EQpAQLSampleSize.SIZE_2,
  [EQpAQLCodeLetter.LETTER_B]: EQpAQLSampleSize.SIZE_3,
  [EQpAQLCodeLetter.LETTER_C]: EQpAQLSampleSize.SIZE_5,
  [EQpAQLCodeLetter.LETTER_D]: EQpAQLSampleSize.SIZE_8,
  [EQpAQLCodeLetter.LETTER_E]: EQpAQLSampleSize.SIZE_13,
  [EQpAQLCodeLetter.LETTER_F]: EQpAQLSampleSize.SIZE_20,
  [EQpAQLCodeLetter.LETTER_G]: EQpAQLSampleSize.SIZE_32,
  [EQpAQLCodeLetter.LETTER_H]: EQpAQLSampleSize.SIZE_50,
  [EQpAQLCodeLetter.LETTER_J]: EQpAQLSampleSize.SIZE_80,
  [EQpAQLCodeLetter.LETTER_K]: EQpAQLSampleSize.SIZE_125,
  [EQpAQLCodeLetter.LETTER_L]: EQpAQLSampleSize.SIZE_200,
  [EQpAQLCodeLetter.LETTER_M]: EQpAQLSampleSize.SIZE_315,
  [EQpAQLCodeLetter.LETTER_N]: EQpAQLSampleSize.SIZE_500,
  [EQpAQLCodeLetter.LETTER_P]: EQpAQLSampleSize.SIZE_800,
  [EQpAQLCodeLetter.LETTER_Q]: EQpAQLSampleSize.SIZE_1250,
  [EQpAQLCodeLetter.LETTER_R]: EQpAQLSampleSize.SIZE_2000,
};
/* eslint-disable no-magic-numbers */
const RANGES: QpNamedMiniRange<EQpAQLLotSize>[] = [
  new QpNamedMiniRange<EQpAQLLotSize>(0, 0, EQpAQLLotSize.LOT_SIZE_0_TO_0), // Non standard
  new QpNamedMiniRange<EQpAQLLotSize>(1, 1, EQpAQLLotSize.LOT_SIZE_1_TO_1), // Non standard
  new QpNamedMiniRange<EQpAQLLotSize>(2, 8, EQpAQLLotSize.LOT_SIZE_2_TO_8),
  new QpNamedMiniRange<EQpAQLLotSize>(9, 15, EQpAQLLotSize.LOT_SIZE_9_TO_15),
  new QpNamedMiniRange<EQpAQLLotSize>(16, 25, EQpAQLLotSize.LOT_SIZE_16_TO_25),
  new QpNamedMiniRange<EQpAQLLotSize>(26, 50, EQpAQLLotSize.LOT_SIZE_26_TO_50),
  new QpNamedMiniRange<EQpAQLLotSize>(51, 90, EQpAQLLotSize.LOT_SIZE_51_TO_90),
  new QpNamedMiniRange<EQpAQLLotSize>(91, 150, EQpAQLLotSize.LOT_SIZE_91_TO_150),
  new QpNamedMiniRange<EQpAQLLotSize>(151, 280, EQpAQLLotSize.LOT_SIZE_151_TO_280),
  new QpNamedMiniRange<EQpAQLLotSize>(281, 500, EQpAQLLotSize.LOT_SIZE_281_TO_500),
  new QpNamedMiniRange<EQpAQLLotSize>(501, 1200, EQpAQLLotSize.LOT_SIZE_501_TO_1200),
  new QpNamedMiniRange<EQpAQLLotSize>(1201, 3200, EQpAQLLotSize.LOT_SIZE_1201_TO_3200),
  new QpNamedMiniRange<EQpAQLLotSize>(3201, 10000, EQpAQLLotSize.LOT_SIZE_3201_TO_10000),
  new QpNamedMiniRange<EQpAQLLotSize>(10001, 35000, EQpAQLLotSize.LOT_SIZE_10001_TO_35000),
  new QpNamedMiniRange<EQpAQLLotSize>(35001, 150000, EQpAQLLotSize.LOT_SIZE_35001_TO_150000),
  new QpNamedMiniRange<EQpAQLLotSize>(150001, 500000, EQpAQLLotSize.LOT_SIZE_150001_TO_500000),
  new QpNamedMiniRange<EQpAQLLotSize>(500001, Infinity, EQpAQLLotSize.LOT_SIZE_500001_TO_INFINITY),
];
/* eslint-enable no-magic-numbers */

export class QpAql {
  public static getHighestSampleSize(sampleSizes: ReadonlyArray<number>): number | never {
    const higherSampleSize: QimaOptionalType<number> = last(sortBy(sampleSizes));

    if (isNil(higherSampleSize)) {
      throw new Error('Could not find the higher sample size');
    }

    return higherSampleSize;
  }

  private readonly _quantity: number;
  private readonly _forcedSamplingQuantity: QimaOptionalType<number>;
  private readonly _inspectionLevel: EQpAQLInspectionLevel;
  private readonly _criticalAQL: AqlValue;
  private readonly _majorAQL: AqlValue;
  private readonly _minorAQL: AqlValue;

  public get lotSizeRange(): QpNamedMiniRange<EQpAQLLotSize> | never {
    const range: QimaOptionalType<QpNamedMiniRange<EQpAQLLotSize>> = RANGES.find((range: QpNamedMiniRange<EQpAQLLotSize>): boolean =>
      range.contains(this._quantity)
    );

    if (isNil(range)) {
      throw new Error('Could not find the range for the AQL');
    }

    return range;
  }

  public get lotSize(): EQpAQLLotSize {
    return this.lotSizeRange.name;
  }

  public get codeLetter(): EQpAQLCodeLetter {
    return AQL_CODE_LETTER_MAPPER[this.lotSize][this._inspectionLevel];
  }

  public get criticalSamplingSize(): IQpAQLSamplingSize {
    return this._samplingSize(this._criticalAQL);
  }

  public get majorSamplingSize(): IQpAQLSamplingSize {
    return this._samplingSize(this._majorAQL);
  }

  public get minorSamplingSize(): IQpAQLSamplingSize {
    return this._samplingSize(this._minorAQL);
  }

  public get samplingSizes(): IQpAQLSamplingSizes {
    return {
      codeLetter: this.codeLetter,
      inspectionLevel: this._inspectionLevel,
      lotSize: this.lotSize,
      quantity: this._quantity,
      critical: this.criticalSamplingSize,
      major: this.majorSamplingSize,
      minor: this.minorSamplingSize,
    };
  }

  public get higherSamplingSize(): IQpAQLSamplingSizeResult | never {
    const { samplingSizes } = this;
    const higherSamplingSize: QimaOptionalType<IQpAQLSamplingSizeResult> = last(
      sortBy(
        [samplingSizes.critical, samplingSizes.major, samplingSizes.minor],
        (samplingSize: Readonly<IQpAQLSamplingSizeResult>): number => {
          return samplingSize.sampleSize;
        }
      )
    );

    if (isNil(higherSamplingSize)) {
      throw new Error('Could not find the higher sampling size');
    }

    return higherSamplingSize;
  }

  public constructor(data: Readonly<IQpAQL>) {
    if (data.quantity < 0) {
      throw new Error('The quantity for the AQL must be greater or equal to 0');
    }

    this._quantity = data.quantity;
    this._inspectionLevel = data.inspectionLevel;
    this._criticalAQL = data.criticalAQL;
    this._majorAQL = data.majorAQL;
    this._minorAQL = data.minorAQL;
    this._forcedSamplingQuantity = data.sampleQuantity;
  }

  private _getForcedSamplingCodeLetter(): QimaOptionalType<EQpAQLCodeLetter> {
    // In this case we have to find the minimum closest acceptable codeLetter to our result
    return Object.keys(CODE_LETTER_TO_SAMPLE_SIZE).reduce(
      (closestLetter: QimaOptionalType<string>, newLetter: string): QimaOptionalType<string> => {
        qpAssert(this._forcedSamplingQuantity, 'Cannot be null');
        const newSampleDifference = this._forcedSamplingQuantity - CODE_LETTER_TO_SAMPLE_SIZE[newLetter];

        // We want to take the closest LOWER one
        if (newSampleDifference < 0) {
          return closestLetter;
        }

        // First value
        if (!closestLetter) {
          return newLetter;
        }

        const oldSampleDifference = this._forcedSamplingQuantity - CODE_LETTER_TO_SAMPLE_SIZE[closestLetter];

        return newSampleDifference < oldSampleDifference ? newLetter : closestLetter;
      },
      null
    ) as unknown as EQpAQLCodeLetter;
  }

  private _samplingSize(aql: Readonly<AqlValue>): IQpAQLSamplingSize {
    let { codeLetter } = this;

    if (this._forcedSamplingQuantity) {
      codeLetter = this._getForcedSamplingCodeLetter() ?? codeLetter;
    }

    const samplingSizeValue = AQL_SAMPLING_SIZE_MAPPER[codeLetter][aql];

    return {
      quantity: this._quantity,
      lotSize: this.lotSize,
      inspectionLevel: this._inspectionLevel,
      codeLetter,
      acceptPoint: samplingSizeValue.acceptPoint,
      rejectPoint: samplingSizeValue.rejectPoint,
      sampleSize: this._getSampleSize(samplingSizeValue.sampleSize),
    };
  }

  /**
   * @description
   * Return the sample size if the quantity is greater than it
   * Else return the quantity
   * You cannot inspect more elements than what you have!
   * @param {Readonly<EQpAQLSampleSize>} sampleSize The theoretical sample size
   * @returns {number} The real sample size (based on the quantity)
   * @private
   */
  private _getSampleSize(sampleSize: Readonly<EQpAQLSampleSize>): number {
    return this._quantity < sampleSize ? this._quantity : sampleSize;
  }
}
