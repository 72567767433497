import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';

export interface IQpAQL {
  readonly quantity: number;
  readonly sampleQuantity?: number;
  readonly inspectionLevel: EQpAQLInspectionLevel;
  readonly criticalAQL: AqlValue;
  readonly majorAQL: AqlValue;
  readonly minorAQL: AqlValue;
}

export interface IQpAQLSamplingSizeValue {
  readonly sampleSize: EQpAQLSampleSize;
  readonly acceptPoint: EQpAQLPoint;
  readonly rejectPoint: EQpAQLPoint;
}

export interface IQpAQLSamplingSizeResult extends IQpAQLSamplingSizeValue {
  readonly sampleSize: number;
}

export interface IQpAQLSamplingSize extends IQpAQLSamplingSizeResult {
  readonly quantity: number;
  readonly lotSize: EQpAQLLotSize;
  readonly inspectionLevel: EQpAQLInspectionLevel;
  readonly codeLetter: EQpAQLCodeLetter;
}

export interface IQpAQLSamplingSizes {
  readonly quantity: number;
  readonly lotSize: EQpAQLLotSize;
  readonly inspectionLevel: EQpAQLInspectionLevel;
  readonly codeLetter: EQpAQLCodeLetter;
  readonly critical: IQpAQLSamplingSizeResult;
  readonly major: IQpAQLSamplingSizeResult;
  readonly minor: IQpAQLSamplingSizeResult;
}

export enum EQpAQLSampleSize {
  SIZE_0 = 0, // Non standard
  SIZE_1 = 1, // Non standard
  SIZE_2 = 2,
  SIZE_3 = 3,
  SIZE_5 = 5,
  SIZE_8 = 8,
  SIZE_13 = 13,
  SIZE_20 = 20,
  SIZE_32 = 32,
  SIZE_50 = 50,
  SIZE_80 = 80,
  SIZE_125 = 125,
  SIZE_200 = 200,
  SIZE_315 = 315,
  SIZE_500 = 500,
  SIZE_800 = 800,
  SIZE_1250 = 1250,
  SIZE_2000 = 2000,
}

export enum EQpAQLPoint {
  POINT_0 = 0,
  POINT_1 = 1,
  POINT_2 = 2,
  POINT_3 = 3,
  POINT_4 = 4,
  POINT_5 = 5,
  POINT_6 = 6,
  POINT_7 = 7,
  POINT_8 = 8,
  POINT_10 = 10,
  POINT_11 = 11,
  POINT_14 = 14,
  POINT_15 = 15,
  POINT_21 = 21,
  POINT_22 = 22,
}

export enum EQpAQLCodeLetter {
  LETTER_A = 'A',
  LETTER_B = 'B',
  LETTER_C = 'C',
  LETTER_D = 'D',
  LETTER_E = 'E',
  LETTER_F = 'F',
  LETTER_G = 'G',
  LETTER_H = 'H',
  LETTER_J = 'J',
  LETTER_K = 'K',
  LETTER_L = 'L',
  LETTER_M = 'M',
  LETTER_N = 'N',
  LETTER_P = 'P',
  LETTER_Q = 'Q',
  LETTER_R = 'R',
  LETTER_Z = 'Z', // Non standard
  LETTER_X = 'X', // Non standard
}

export enum EQpAQLLotSize {
  LOT_SIZE_0_TO_0 = '0-0',
  LOT_SIZE_1_TO_1 = '1-1',
  LOT_SIZE_2_TO_8 = '2-8',
  LOT_SIZE_9_TO_15 = '9-15',
  LOT_SIZE_16_TO_25 = '16-25',
  LOT_SIZE_26_TO_50 = '26-50',
  LOT_SIZE_51_TO_90 = '51-90',
  LOT_SIZE_91_TO_150 = '91-150',
  LOT_SIZE_151_TO_280 = '151-280',
  LOT_SIZE_281_TO_500 = '281-500',
  LOT_SIZE_501_TO_1200 = '501-1200',
  LOT_SIZE_1201_TO_3200 = '1201-3200',
  LOT_SIZE_3201_TO_10000 = '3201-10000',
  LOT_SIZE_10001_TO_35000 = '10001-35000',
  LOT_SIZE_35001_TO_150000 = '35001-150000',
  LOT_SIZE_150001_TO_500000 = '150001-500000',
  LOT_SIZE_500001_TO_INFINITY = '500001-infinity',
}

export enum EQpAQLInspectionLevel {
  // General inspection levels
  I = `I`,
  II = `II`,
  III = `III`,

  // Special inspection levels
  S1 = `S1`,
  S2 = `S2`,
  S3 = `S3`,
  S4 = `S4`,
}
