import { Dict } from 'mixpanel-browser';

export interface ITrackingEvent {
  id: ETrackingEventId;
  properties?: Dict;
}

export enum ETrackingEventId {
  'LOGIN' = 'login',
  'BOOKING_SCHEDULED' = 'Booking - scheduled',
  'BOOKING_UPDATED' = 'Booking - updated',
  'INSPECTOR_INSPECTION_STARTED' = 'inspection started',
  'INSPECTOR_INSPECTION_COMPLETED' = 'inspection completed',
  'INSPECTOR_SHARE_INSPECTION_OPEN' = 'inspector share inspection - open',
  'INSPECTOR_SELF_INSPECTION_STARTED_FROM_LINK' = 'inspector share inspection - self inspection started from link',
  'INSPECTOR_SELF_INSPECTION_STARTED_FROM_BUTTON' = 'inspector share inspection - self inspection started from "start a new" button',
  'INSPECTOR_TOOLBOX_OPEN' = 'inspector toolbox - open',
  'INSPECTOR_TOOLBOX_CLOSE' = 'inspector toolbox - close',
  'INSPECTOR_TOOLBOX_CLICK' = 'inspector toolbox - click',
  'INSPECTOR_TOOLBOX_TOGGLE_OFFLINE' = 'inspector toolbox - toggle offline',
  'NOTIFICATION_OPEN' = 'notification - open',
  'SETTINGS_CUSTOM_FIELD_CREATE' = 'settings - custom field - create',
  'SETTINGS_CUSTOM_FIELD_UPDATE' = 'settings - custom field - update',
  'PO_PRODUCT_PRODUCT_CREATE' = 'POs and Product - product - create',
  'PO_PRODUCT_PRODUCT_UPDATE' = 'POs and Product - product - update',
  'BOOKING_FAST_TRACK' = 'Booking - Fast track',
  'WORKFLOW_ADDENDUM_SET_TEXT' = 'Workflow - addendum - set text',
  'WORKFLOW_ADDENDUM_DELETE_TEXT' = 'Workflow - addendum - delete text',
  'WORKFLOW_ADDENDUM_SET_PICTURE' = 'Workflow - addendum - set picture',
  'WORKFLOW_ADDENDUM_DELETE_PICTURE' = 'Workflow - addendum - delete picture',
  'INSPECTOR_IMAGES_MASS_CAPTION_SET' = 'Inspector images - mass set caption',
  'INSPECTOR_IMAGES_MASS_DELETE' = 'Inspector images - mass delete',
  'INSPECTOR_IMAGES_MASS_SPOTLIGHT' = 'Inspector images - mass spotlight',
  'INSPECTOR_IMAGES_MASS_LINK_TO_TEST_RESULT' = 'Inspector images - mass link pictures to a test result',
  'INSPECTOR_IMAGES_ANALYSIS_ROTATION_CHECK' = 'Inspector images - analysis rotation check',
  'INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK' = 'Inspector images - analysis blur check',
  'INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK_ACTION' = 'Inspector images - analysis blur check - action',
  'INSPECTOR_IMAGE_SINGLE_CAPTION_SET' = 'Inspector image - single set caption',
  'INSPECTOR_IMAGE_SINGLE_DELETE' = 'Inspector image - single delete',
  'INSPECTOR_IMAGE_SINGLE_SPOTLIGHT' = 'Inspector image - single spotlight',
  'INSPECTOR_IMAGE_SINGLE_LINK_TO_TEST_RESULT' = 'Inspector image - single link picture to a test result',
  'INSPECTOR_IMAGE_ADDS_COVER_PHOTO' = 'Inspector image - adds inspection cover photo',
  'TESTS_CHECKLIST_MULTIPLE_ANSWERS_ALLOWED' = 'Checklist - Test Checklist - Number Type & Multiple Answer Option',
  'TESTS_CHECKLIST_SCAN' = 'Checklist - Test Checklist - Scan',
  'REPORT_HTML_REPORT_WORKMANSHIP_EXPANDED' = 'Report - HTML Report - Expand Workmanship',
  'REPORT_HTML_REPORT_TEST_CHECKLIST_EXPANDED' = 'Report - HTML Report - Expand Test Checklist',
  'REPORT_HTML_REPORT_INSPECTION_DETAILS_EXPANDED' = 'Report - HTML Report - Expand Inspection Details',
  'REPORT_HTML_REPORT_INSPECTION_WORKFLOW_EXPANDED' = 'Report - HTML Report - Expand Inspection Workflow',
  'REPORT_HTML_REPORT_INSPECTION_REMARKS_EXPANDED' = 'Report - HTML Report - Expand Inspection Remarks',
  'REPORT_HTML_REPORT_MEASUREMENTS_SEE_DETAILS' = 'Report - HTML Report - Measurements See Details',
  'HEADER_QUICK_SEARCH_EXPAND' = 'Header - Quick Search - Expand',
  'HEADER_QUICK_SEARCH_SELECT_RESULT' = 'Header - Quick Search - Select Result',
  'HOME_ANALYTICS_CARD_INTERACTIONS' = 'Home - Analytics Card - Click interactions',
  'HOME_ANALYTICS_CARD_VIEW_ALL' = 'Home - Analytics Card - View all',
  'SEARCH_PRODUCT_BY_SCAN' = 'Search product by scan',
  'REPORTS_INSPECTION_DATE_CHANGED' = 'Reports - Update Inspection date',
  'SETTINGS_RATINGS_ADD_NEW_RATING' = 'Settings - Ratings - Add new rating',
  'SETTINGS_RATINGS_ADD_NEW_GRADE' = 'Settings - Ratings - Add new grade',
  'SETTINGS_RATINGS_DELETE_RATING' = 'Settings - Ratings - Delete rating',
  'INSPECTOR_SETTINGS_CLEAR_OFFLINE_CACHE' = 'Inspector settings - Clear offline cache',
  'INSPECTOR_SETTINGS_ACTIVATE_OFFLINE_MODE' = 'Inspector settings - Activate offline mode',
  'INSPECTOR_SETTINGS_DEACTIVATE_OFFLINE_MODE' = 'Inspector settings - Deactivate offline mode',
  'INSPECTOR_INSPECTION_COORDINATES_ISSUE' = 'Inspection - coordinates issue',
  'INSPECTOR_UPLOAD_PICTURE' = 'Inspector - upload picture',
  'INSPECTOR_INSPECTION_ADDS_PRODUCED_QUANTITY' = 'Inspection - adds produced quantity',
  'INSPECTOR_INSPECTION_COMPLETE_DEFECTS_REVIEW' = 'Inspection - complete defects review',
  'INSPECTOR_INSPECTION_CONFIRM_SUMMARY_REVIEW' = 'Inspection - confirm summary review',
}
