import { qpIsNotNil } from '@library/functions/checks/qp-is-not-nil';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { EQpMimeTypeCategory } from '@library/models/qp-mime-type.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import {
  addInspectionPictureSuccess,
  saveProductPicture,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import { getGeneratedEntityId } from '@one/app/shared/functions/get-generated-entity-id/get-generated-entity-id';
import { EChecklistImageType, IChecklistImageData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { BlurDetectionService } from '@one/app/shared/services/blur-detection/blur-detection.service';
import { InspectionService } from '@one/app/shared/services/inspection/inspection.service';
import { ETrackingPictureSource, IInspectorPictureSettings } from '@one/app/shared/services/pictures/picture-importer.models';
import { PreviousRouteService } from '@one/app/shared/services/router/previous-route.service';
import { ETrackingEventId } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.models';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import {
  anImageAnalysisCheck,
  anInspectorAddsCoverPhotoEvent,
  anInspectorPictureUploadEvent,
} from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.utils';
import { IStoreState } from '@one/app/store/store.models';
import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EQimaSnackbarType, QimaOptionalType, QimaSnackbarService } from '@qima/ngx-qima';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PictureImporterService {
  public isAnalyzing: boolean = false;
  private _lastPerformanceTime: number = performance.now();

  public constructor(
    private readonly _store: Store<IStoreState>,
    private readonly _previousRouteService: PreviousRouteService,
    private readonly _translateService: TranslateService,
    private readonly _qpDateService: QpDateService,
    private readonly _blurDetectionService: BlurDetectionService,
    private readonly _inspectionService: InspectionService,
    private readonly _trackingService: TrackingService,
    private readonly _qimaSnackbarService: QimaSnackbarService
  ) {}

  public convertFileListToArray(fileList: QimaOptionalType<FileList>): File[] {
    return Array.from(new Array(fileList?.length ?? 0))
      .map((_, i): number => i)
      .map((fileIndex: number): QimaOptionalType<File> => fileList?.item(fileIndex))
      .filter(qpIsNotNil);
  }

  public analyzePictures$(files: File[]): Observable<File[]> {
    this.isAnalyzing = true;
    const validPictureFiles = files.filter((file): boolean => file.type.startsWith(EQpMimeTypeCategory.IMAGE.toString()));

    if (!validPictureFiles.length) {
      this._qimaSnackbarService.open({
        type: EQimaSnackbarType.ERROR,
        message: this._translateService.instant('picture-importer-dialog.unsupported-file-type'),
      });

      return throwError((): Error => new Error('Unsupported file type'));
    }

    // Track blur check time
    this._lastPerformanceTime = performance.now();

    return from(this._blurDetectionService.filterImages(validPictureFiles)).pipe(
      catchError((): Observable<never> => {
        this._qimaSnackbarService.open({
          type: EQimaSnackbarType.ERROR,
          message: this._translateService.instant('picture-importer-dialog.unknown-error'),
        });

        return EMPTY;
      }),
      finalize((): void => {
        this.isAnalyzing = false;
        this._logPerformanceTrackingEvent(ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK, validPictureFiles.length);
      })
    );
  }

  public submitPictures(
    files: File[],
    pictureImportLocation: IInspectorPictureSettings,
    successEvent?: EventEmitter<IChecklistImageData>,
    failureEvent?: EventEmitter<void>
  ): void {
    this._trackingService.track(
      anInspectorPictureUploadEvent(
        pictureImportLocation.inspectionId,
        pictureImportLocation?.pictureSource ?? ETrackingPictureSource.GALLERY
      )
    );

    of(...files)
      .pipe(
        concatMap(
          (file): Observable<IChecklistImageData> =>
            pictureImportLocation.imageElementType === EQpImageElementType.PRODUCT_PICTURE
              ? this._submitActionPicture$(file, pictureImportLocation)
              : this._submitPicture$(file, pictureImportLocation)
        )
      )
      .subscribe({
        next(image): void {
          successEvent?.emit(image);
        },
        error(): void {
          failureEvent?.emit();
        },
      });
  }

  public getCameraPreviousUrl(pictureImportLocation: IInspectorPictureSettings): string {
    const categoryId: QimaOptionalType<string> = pictureImportLocation.entityId?.split('-').slice(0, 2).join('-');

    if (!pictureImportLocation.inspectionId) {
      return IspRoutes.inspection();
    } else if (pictureImportLocation.imageElementType === EQpImageElementType.ANSWERS && categoryId && pictureImportLocation.entityId) {
      return IspRoutes.testInTestsChecklist(pictureImportLocation.inspectionId.toString(), categoryId, pictureImportLocation.entityId);
    } else if (pictureImportLocation.imageElementType === EQpImageElementType.DEFECTS && categoryId) {
      return pictureImportLocation.purchaseOrderProductId
        ? IspRoutes.defectsChecklistProduct(
            pictureImportLocation.inspectionId.toString(),
            categoryId,
            pictureImportLocation.purchaseOrderProductId.toString()
          )
        : IspRoutes.defectsChecklist(pictureImportLocation.inspectionId.toString(), categoryId);
    } else if (pictureImportLocation.imageElementType === EQpImageElementType.PRODUCT_PICTURE && categoryId) {
      return IspRoutes.productPicture(pictureImportLocation.inspectionId.toString(), categoryId);
    }

    const previousUrl: string = this._previousRouteService.getPreviousUrl();

    if (previousUrl !== '/') {
      return previousUrl;
    }

    return IspRoutes.inspectionSteps(pictureImportLocation.inspectionId);
  }

  private _submitActionPicture$(file: File, pictureImportLocation: IInspectorPictureSettings): Observable<IChecklistImageData> {
    return this._inspectionService.addActionDocument$(pictureImportLocation.inspectionId, file).pipe(
      map((response: HttpResponse<void>): IChecklistImageData => {
        const documentId = getGeneratedEntityId(response);

        if (documentId) {
          this._store.dispatch(
            saveProductPicture({
              actionId: pictureImportLocation.entityId as string,
              action: {
                documentId,
              },
            })
          );
          this._trackingService.track(anInspectorAddsCoverPhotoEvent(pictureImportLocation.inspectionId));
        }

        return {
          date: this._qpDateService.getDate(),
          timezone: this._qpDateService.getTimezone(),
          type: EChecklistImageType.PHOTO,
          imageId: documentId,
        };
      })
    );
  }

  private _submitPicture$(file: File, pictureImportLocation: IInspectorPictureSettings): Observable<IChecklistImageData> {
    const data: IChecklistImageData = {
      date: QpDateService.now,
      timezone: QpDateService.timezone,
      type: EChecklistImageType.PHOTO,
      purchaseOrderProductId: pictureImportLocation.purchaseOrderProductId,
    };

    return this._inspectionService
      .addOrUpdateInspectionPicture$(
        pictureImportLocation.inspectionId,
        file,
        data,
        pictureImportLocation.imageElementType,
        pictureImportLocation.entityId
      )
      .pipe(
        tap((pictureId: string): void => {
          this._store.dispatch(
            addInspectionPictureSuccess({
              data,
              elementType: pictureImportLocation.imageElementType,
              elementPathId: pictureImportLocation.entityId,
              newImageId: pictureId,
            })
          );
        }),
        map(
          (pictureId: string): IChecklistImageData => ({
            ...data,
            imageId: pictureId,
          })
        )
      );
  }

  private _logPerformanceTrackingEvent(
    eventId: ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK | ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_ROTATION_CHECK,
    fileCount: number
  ): void {
    const currentTime = performance.now();
    const timeElapsed = Math.floor(currentTime - this._lastPerformanceTime) / 1000;

    this._trackingService.track(anImageAnalysisCheck(eventId, timeElapsed, fileCount));
    this._lastPerformanceTime = currentTime;
  }
}
