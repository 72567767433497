import { IReportTemplateGetDTO } from '@library/dto/report/report-customization.dto';
import { WorkflowSumRatingConfigDTO } from '@library/dto/workflow/settings/workflow-sum-rating-config.dto';
import { IBrdInspectionReferenceSample, ISampleCollection } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { IAuditsChecklist } from '@one/app/pages/brd/pages/workflow/pages/audit-checklist/shared/models/brd-workflow-audit-checklist.models';
import { IDefectsChecklist } from '@one/app/pages/brd/pages/workflow/pages/defect-checklist/shared/models/defects.models';
import { EAuditWorkflowTemplateStepName } from '@one/app/pages/brd/pages/workflow/shared/models/brd-workflow-audit-template.models';
import { IWorkflowTemplateStepActionChecklist } from '@one/app/pages/brd/pages/workflow/shared/models/brd-workflow-template.models';
import { IMeasurementChecklistProduct, IProductVariances } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { ITestsChecklistDetails } from '@one/app/shared/models/checklist/tests-checklist.models';
import { AuditedEntity } from '@one/app/shared/models/entity/audited-entity.models';
import { IInspectionType } from '@one/app/shared/models/inspections/inspection.models';
import { IWorkflowCheckTemplate } from '@one/app/shared/models/workflow/workflow.models';
import { EWorkflowEditionStepActionType } from '@one/app/shared/models/workflows/workflow.models';
import { FormControl } from '@ngneat/reactive-forms';
import { QimaOptionalType, QimaUuidType } from '@qima/ngx-qima';

export interface IWorkflowTemplateStepActionDocument {
  filename?: string;
}

export interface IWorkflowTemplateContent {
  steps: IWorkflowTemplateStep[];
}

/**
 * @description
 * WARNING: any addition of new key should be reflected in {@link BrdInspectionWorkflowActionComponent}
 */
export type WorkflowAnyStepActionTypeType = EWorkflowTemplateStepActionType | EWorkflowEditionStepActionType;

export interface IWorkflowTemplateStepActionInspectionPreparation extends IWorkflowTemplateStepCommonAction {
  type: EWorkflowTemplateStepActionType.INSPECTION_PREPARATION;
  /**
   * @description
   * Hide the inspection preparation step content to the inspector (the card will not be clickable)
   * Usually, the default value is true
   */
  updatableByInspector: boolean;
  /**
   * @description
   * Change the way to calculate the sample size
   * If the value is {@link EWorkflowTemplateStepActionInspectionPreparationCalculationMethod.ORDERED_QUANTITY},
   * Then the sample size will equal the lot size
   * Usually, the default value is {@link EWorkflowTemplateStepActionInspectionPreparationCalculationMethod.PACKED_QUANTITY}
   */
  calculationMethod: EWorkflowTemplateStepActionInspectionPreparationCalculationMethod;
}

export enum EWorkflowTemplateStepActionInspectionPreparationCalculationMethod {
  PACKED_QUANTITY = 'PACKED_QUANTITY',
  ORDERED_QUANTITY = 'ORDERED_QUANTITY',
}

export enum EMeasurementOverallResultCalculationMethod {
  SAMPLES_OUT_OF_TOLERANCE = 'SAMPLES_OUT_OF_TOLERANCE',
  POINTS_OF_MEASUREMENT_OUT_OF_TOLERANCE = 'POINTS_OF_MEASUREMENT_OUT_OF_TOLERANCE',
}

export enum EWorkflowMeasurementProof {
  PICTURES = 'PICTURES',
  SPOTLIGHT = 'SPOTLIGHT',
  COMMENTS = 'COMMENTS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export type WorkflowTemplateStepActionType = IWorkflowTemplateStepAction | IWorkflowTemplateStepActionInspectionPreparation;

export interface IWorkflowTemplateStepCommonAction {
  type: WorkflowAnyStepActionTypeType;
  actionName?: string;
  id?: string;
  uuid?: QimaUuidType;
}

export interface IWorkflowStepActionReport extends IWorkflowTemplateStepCommonAction {
  ccRecipients: string[];
  bccRecipients: string[];
}

export interface IWorkflowStepActionAddendum extends IWorkflowTemplateStepCommonAction {
  text: string;
  fileId: string;
}

/**
 * @todo use split models to have better typings; see {@link WorkflowTemplateStepActionType}
 */
export interface IWorkflowTemplateStepAction extends IWorkflowTemplateStepCommonAction {
  testsChecklistId?: number;
  commentsMandatoryWhenNotApplicable?: boolean;
  photosMandatoryWhenDefectsFound?: boolean;
  commentsMandatoryWhenDefectsFound?: boolean;
  defectsChecklistId?: number;
  testsChecklist?: ITestsChecklistDetails;
  defectsChecklist?: IDefectsChecklist;
  referenceSample?: IBrdInspectionReferenceSample;
  sampleCollection?: ISampleCollection;
  products?: IMeasurementChecklistProduct[];
  documentId?: string;
  instructions?: string;
  checklist?: IWorkflowTemplateStepActionChecklist;
  document?: IWorkflowTemplateStepActionDocument;
  actionName?: string;
  isDocumentFromWorkflowTemplate?: boolean;
  checks?: IWorkflowCheckTemplate[];
  canInspectionBeMarkedAsPending?: boolean;
  measuresChecklists?: IProductVariances[];
  auditsChecklist?: IAuditsChecklist;
  auditsChecklistId?: QimaOptionalType<number>;
  ccRecipients?: string[];
  bccRecipients?: string[];
  calculationMethod?: EWorkflowTemplateStepActionInspectionPreparationCalculationMethod;
  updatableByInspector?: boolean;
  fileId?: string;
  text?: string;
  name?: string;
  measurementOverallResultCalculationMethod?: EMeasurementOverallResultCalculationMethod;
  expectedResult?: string;
  isCommentRequiredWhenMarkedAsNA?: boolean;
  requiredProofs?: EWorkflowMeasurementProof[];
  pictureSource?: EStartingPictureSource;

  /**
   * @description
   * Useful to:
   * - Hide the button to add a new action after this one (for example the findings summary)
   *
   * `null` or `undefined` will count as true (optional in that case means true)
   */
  canAddNewActionsAfter?: QimaOptionalType<boolean>;
  /**
   * @description
   * Useful to:
   * - show waring to user if a workflow action (e.g checklist) is not available in the local indexed DB
   */
  isAvailableInOffline?: QimaOptionalType<boolean>;
}

export interface IWorkflowTemplateSettings {
  reportTemplate?: IReportTemplateGetDTO;
  sumRatingConfig?: WorkflowSumRatingConfigDTO;
}

export interface IWorkflowTemplate extends AuditedEntity {
  id: number;
  name: string;
  content?: IWorkflowTemplateContent;
  isQima?: boolean;
  inspectionTypeLabel?: string;
  inspectionTypeDescription?: string;
  inspectionTypeId?: number;
  auditTypeId?: number;
  inspectionType?: IInspectionType;
  settings?: IWorkflowTemplateSettings;
  hasSimplifiedMeasurementChecklist?: boolean;
}

export interface ISharedWorkflowTemplate extends IWorkflowTemplate {
  workflowTemplateId: number;
}

export interface IWorkflowTemplateFormGroup {
  id: FormControl<number | null>;
  name: FormControl<string>;
  content?: FormControl<IWorkflowTemplateContent>;
  isQima?: FormControl<boolean>;
  inspectionTypeLabel?: FormControl<string>;
  inspectionTypeDescription?: FormControl<string>;
  inspectionTypeId: FormControl<number | null>;
  auditTypeId: FormControl<number | null>;
  createdBy?: FormControl<string>;
  createdDate?: FormControl<Date>;
  lastModifiedBy?: FormControl<string>;
  lastModifiedDate?: FormControl<string>;
}

export interface IWorkflowTemplateStep {
  id: string;
  name: EWorkflowTemplateStepName | EAuditWorkflowTemplateStepName;
  actions: WorkflowTemplateStepActionType[];
}

export enum EWorkflowTemplateStepName {
  BEFORE_INSPECTION = 'Before inspection',
  DURING_INSPECTION = 'During inspection',
  AFTER_INSPECTION = 'After inspection',
}

export enum EWorkflowTemplateStepActionType {
  INSPECTION_DETAILS = 'INSPECTION_DETAILS',
  INSPECTION_PREPARATION = 'INSPECTION_PREPARATION',
  PRODUCT_PICTURE = 'PRODUCT_PICTURE',
  INSTRUCTIONS = 'INSTRUCTIONS',
  TEMPLATE_DOCUMENT = 'TEMPLATE_DOCUMENT',
  TEMPLATE_TESTS_CHECKLIST = 'TEMPLATE_TESTS_CHECKLIST',
  TEMPLATE_DEFECTS_CHECKLIST = 'TEMPLATE_DEFECTS_CHECKLIST',
  REPORT = 'REPORT',
  ADDENDUM = 'ADDENDUM',
  MEASURES_CHECKLIST = 'MEASURES_CHECKLIST',
  SIMPLIFIED_MEASUREMENTS_CHECKLIST = 'SIMPLIFIED_MEASUREMENTS_CHECKLIST',
  FINDINGS_SUMMARY_REVIEW = 'FINDINGS_SUMMARY_REVIEW',
  AUDIT_DETAILS = 'AUDIT_DETAILS',
  AUDIT_PLAN = 'AUDIT_PLAN',
  TEMPLATE_AUDITS_CHECKLIST = 'TEMPLATE_AUDITS_CHECKLIST',
  DEFECTS_CHECKLIST = 'DEFECTS_CHECKLIST',
  TESTS_CHECKLIST = 'TESTS_CHECKLIST',
  ADD_RECIPIENTS = 'ADD_RECIPIENTS',
  SITE_LOCATION = 'SITE_LOCATION',
  DOCUMENT = 'DOCUMENT',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  STARTING_PICTURES = 'STARTING_PICTURES',
}

export enum EStartingPictureSource {
  CAMERA = 'CAMERA',
  CAMERA_AND_UPLOAD = 'CAMERA_AND_UPLOAD',
}

export type SelfStartingWorkflowTemplateType = IWorkflowTemplate | ISharedWorkflowTemplate;
